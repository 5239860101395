@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  transition: background-color 100ms ease;
  transition: color 100ms ease;
  transition: border-color 100ms ease;
  font-family: 'Roboto', sans-serif;
}

:root {
  --color-bg: #121212;
  --color-depth1: #1f1f1f;
  --color-depth2: #222222;
  --color-depth3: #262626;
  --color-depth4: #282828;
  --color-depth5: #2c2c2c;
  --color-depth6: #2f2f2f;
  --color-depth7: #333333;
  --color-depth8: #353535;
  --color-depth9: #383838;
  --color-secondaryPale: #ff7597;
  --color-secondary: #f50057;
  --color-primary: #0bdbc5;
  --color-error: #cf6679;
  --color-alert: #017957;
  --color-active: #bb014e;
  --color-onPrimary: #121212;
  --color-onSecondary: #ffffff;
  --button-outline: rgba(255, 255, 255, 0.23);
  --color-text: #ffffff;
  --color-textHinted: rgba(255, 255, 255, 0.5);
}

/* span {
  color: var(--color-text);
} */

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
::-webkit-scrollbar {
  background-color: rgba(255, 255, 255, 0);
  width: 10px;
  height: 10px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0);
}
::-webkit-scrollbar-track:hover {
  background-color: rgba(210, 210, 210, 0.15);
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: rgba(189, 163, 163, 0.3);
  border-radius: 6px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(210, 210, 210, 0.5);
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

.lightMode {
  --color-bg: #e0dede;
  --color-depth1: #ffffff;
  --color-depth2: #f2f6f6;
  --color-depth3: #e6eeee;
  --color-depth4: #dae5e6;
  --color-depth5: #ceddde;
  --color-depth6: #c1d4d5;
  --color-depth7: #b5cbcd;
  --color-depth8: #90b1b4;
  --color-depth9: #84a9ac;
  --color-secondaryPale: #ff7597;
  --color-secondary: #f50057;
  --color-primary: #10ccb9;
  --color-error: #cf6679;
  --color-alert: #017957;
  --color-active: #bb014e;
  --color-onPrimary: rgba(37, 38, 39, 0.87);
  --color-onSecondary: #ffffff;
  --color-text: rgba(37, 38, 39, 0.87);
  --color-textHinted: rgba(37, 38, 39, 0.38);
  --button-outline: rgba(0, 0, 0, 0.23);
}

.drag * {
  pointer-events: none;
}

@media (hover: none) and (pointer: coarse) {
  .hideOverflow {
    overflow: hidden;
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
  }
}

.flex {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.paxDropdown .MuiInput-underline.Mui-disabled:before {
  border-bottom-style: solid;
}

.paxDropdown input {
  color: #ffffff !important;
}

.lightMode .paxDropdown input {
  color: #000000 !important;
  opacity: 1 !important;
}

.paxDropdown label {
  color: rgba(255, 255, 255, 0.54) !important;
}

.lightMode .paxDropdown label {
  color: rgba(37, 38, 39, 0.54) !important;
}

.keyRow {
  width: 100%;
  height: 63px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.key {
  width: 33%;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.key:hover {
  background: rgba(235, 235, 235, 0.11);
}

.react-rte > div:first-child {
  margin: 0;
  border-bottom: none;
  position: absolute;
  bottom: 0px;
  right: 5px;
  z-index: 100;
  padding: 0;
}

.inline-btn {
  background: #c1d4d5 !important;
  -webkit-filter: invert(1);
          filter: invert(1);
  z-index: 10;
}

.inline-btn.IconButton__isActive___2Ey8p {
  background: #84a9ac !important;
}

.lightMode .inline-btn {
  -webkit-filter: invert(0);
          filter: invert(0);
}

.image-grid {
  display: grid;
  width: 100%;
  min-height: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  /* grid-auto-rows: 150px; */
  grid-gap: 10px;
}

.image-grid > div {
  padding: 10px;
  min-height: 170px;
  border-radius: 5px;
}

.image-grid > div:hover {
  background: rgba(0, 0, 0, 0.3);
}

.image-grid .image {
  width: 100%;
  height: 150px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.current-image .image {
  width: 100%;
  height: 25vh;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.current-image {
  width: 100%;
  position: relative;
}

.current-image .close {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 100;
}

.multiple-image {
  border-radius: 4px;
  position: relative;
  border: 2px transparent solid;
}

.multiple-image .multiple-image-checkbox {
  display: none;
  position: absolute;
  top: -4px;
  right: -4px;
}

.multiple-image svg {
  fill: dodgerblue;
  width: 28px;
  height: 28px;
}

.multiple-image:hover,
.multiple-image.selected {
  border: 2px dodgerblue solid;
}

.multiple-image:hover .multiple-image-checkbox,
.multiple-image.selected .multiple-image-checkbox {
  display: block;
}

input:-internal-autofill-selected {
  background-color: transparent !important;
  color: unset !important;
}

.settings-main {
  -webkit-flex: 1 1;
          flex: 1 1;
  max-width: 900px;
  width: 100%;
  margin: auto;
  padding-top: 20px;
}

.table-alert {
  fill: #ffc107;
  fill-opacity: 1;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--color-depth1);
  min-height: 100vh;
}

.App-Background {
  height: 100vh;
  width: 100vw;
  background: var(--color-depth1);
}

.ReservationDashboard {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 1px;
  overflow: scroll;
}

.App-Header {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 50px;
  padding: 10px;
  color: var(--color-text);
  background: var(--color-depth4);
  z-index: 11;
}

.lightMode .App-Header {
  background: var(--color-depth1);
}

.DashboardContainer {
  height: 100vh;
  width: 100vw;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  background: var(--color-bg);
}

.SideBarContainer {
  min-width: 300px;
  max-width: 300px;
  padding-top: 50px;
  padding-bottom: 40px;
  height: 100vh;
  background: var(--color-depth2);
  overflow: scroll;
  position: relative;
  -webkit-overflow-scrolling: touch;
}

.SideBarContainer.Mobile {
  width: 100vw;
  padding: 0px 0px 56px 0px;
  max-width: 100vw;
  height: 100%;
}

.Mobile > .SideBarElement {
  font-size: 120% !important;
  border-bottom-color: var(--color-depth9) !important;
}

#menu- {
  z-index: 13000 !important;
}

.SideBarFooter {
  display: -webkit-flex;
  display: flex;
  position: fixed;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 300px;
  bottom: 0px;
  left: 0px;
  height: 40px;
  background: var(--color-depth3);
  font-size: 14px;
  line-height: 20px;
  padding: 10px;
  color: var(--color-text);
}

.SideBarElement {
  position: relative;
  padding: 14px 10px 10px 10px;
  background: var(--color-depth4);
}

.SideBarElement .Blocked {
  height: 100%;
  width: 10px;
  position: absolute;
  right: 0px;
  top: 0px;
  /* background-image: url('/tableBlocked.png'); */
}

.SideBarElement .New {
  padding: 2px;
  background: var(--color-secondary) !important;
  text-align: center;
  font-size: 9px;
  margin-left: 4px;
  color: var(--color-text) !important;
}

.SideBarElement,
.SideBarElement * {
  cursor: pointer;
}

.SideBarElement.-active {
  background: var(--color-active);
}

.SideBarElement.-alert {
  background: var(--color-alert);
}

.EditContainer {
  width: 300px;
  height: 100vh;
  background: var(--color-text);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 101;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-direction: column;
          flex-direction: column;
  background: var(--color-depth6);
}

.EditContainer * {
  color: var(--color-text) !important;
}

.EditContainer select option {
  background: var(--color-depth6) !important;
  color: var(--color-text);
}

.EditHeader {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  padding: 0px 10px 1px 10px;
  border-bottom: solid 0.5px rgba(255, 255, 255, 0.4);
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  color: var(--color-bg);
  font-size: 20px;
  font-weight: 500;
}

.EditBody {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  -webkit-flex-grow: 10;
          flex-grow: 10;
}

.EditBody .MuiInput-underline:before {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.EditBody .react-calendar {
  background: none;
  margin-bottom: 10px;
}

.EditBody .react-calendar__tile:disabled {
  background-color: rgba(255, 255, 255, 0.2);
}

.EditBody .MuiFormHelperText-root.Mui-error {
  color: var(--color-error) !important;
}

.EditBody .react-calendar__navigation button[disabled] {
  background-color: rgba(255, 255, 255, 0.4);
}

button.react-calendar__navigation__arrow {
  font-size: larger;
}

.ViewContainer {
  -webkit-flex-grow: 10;
          flex-grow: 10;
  height: 100vh;
  padding: 60px 10px 10px 10px;
  overflow: hidden;
  position: relative;
}

.changeFloor {
  position: absolute !important;
  bottom: 10px;
  left: 10px;
  color: var(--color-text) !important;
}

.changeFloor * {
  border-color: var(--color-text) !important;
}

.changeFloor label {
  color: var(--color-text) !important;
}

.RightSideBarHeader {
  height: 50px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding: 10px;
  font-size: 20px;
  font-weight: 500;
}

.lightMode .RightSideBarHeader {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.Table {
  position: absolute;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  color: var(--color-text);
  background-position: center center;
  background-size: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.Table * {
  cursor: pointer;
  z-index: 1;
}

@media (min-width: 1200px) {
  .Table {
    font-size: 20px;
  }
}

.TableBefore {
  content: attr(data-date-1);
  position: absolute;
  top: -12%;
  padding: 2px 4px;
  background: var(--color-depth1);
  color: var(--color-text);
  font-size: 80%;
  border-radius: 1px;
}

.TableBeforeBlocked::before {
  content: '. . .';
  color: var(--color-depth1);
  /* background-image: url(/blocked@2x.png); */
  background-size: 12px;
  background-position: center;
  background-repeat: no-repeat;
}

.TableAfter {
  content: attr(data-date-2);
  position: absolute;
  bottom: -17%;
  padding: 2px 4px;
  background: var(--color-depth1);
  color: var(--color-text);
  font-size: 80%;
  border-radius: 1px;
}

.TableAfterBlocked::before {
  content: '. . .';
  color: var(--color-depth1);
  /* background-image: url(/blocked@2x.png); */
  background-size: 12px;
  background-position: center;
  background-repeat: no-repeat;
}

.TableActive {
  position: absolute;
  bottom: 45%;
  width: 60%;
  height: 10%;
  background: #97004a;
  border-radius: 10px;
  overflow: hidden;
}

.TableActive > span {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgba(255, 255, 255, 1);
  border-radius: 0px 10px 10px 0px;
}

.Table-Hover::before {
  content: '';
  height: 120%;
  width: 120%;
  border: solid 2px white;
  position: absolute;
  border-radius: 5px;
}

.Table-Old::before {
  content: '';
  height: 120%;
  width: 120%;
  border: solid 2px white;
  border-style: dashed;
  position: absolute;
  border-radius: 5px;
}

.Table.horizontal-2 {
  /* background-image: url(/2-horizontal@2x.png); */
}

.Table.horizontal-2-active {
  /* background-image: url(/2-horizontal-active@2x.png); */
}

.Table.horizontal-2-alert {
  /* background-image: url(/2-horizontal-alert@2x.png); */
}

.Table.horizontal-2-blocked {
  /* background-image: url(/2-horizontal-blocked@2x.png); */
}

.Table.vertical-2 {
  /* background-image: url(/2-vertical@2x.png); */
}

.Table.vertical-2-active {
  /* background-image: url(/2-vertical-active@2x.png); */
}

.Table.vertical-2-alert {
  /* background-image: url(/2-vertical-alert@2x.png); */
}

.Table.vertical-2-blocked {
  /* background-image: url(/2-vertical-blocked@2x.png); */
  color: rgba(255, 255, 255, 0.5);
}

.Table.vertical-bar {
  /* background-image: url(/bar-vertical@2x.png); */
  background-repeat: no-repeat;
}

.Table.vertical-bar-alert {
  /* background-image: url(/bar-vertical-alert@2x.png); */
  background-repeat: no-repeat;
}

.Table.vertical-bar-active {
  /* background-image: url(/bar-vertical-active@2x.png); */
  background-repeat: no-repeat;
}

.Table.vertical-bar-blocked {
  /* background-image: url(/bar-vertical-blocked@2x.png); */
  background-repeat: no-repeat;
}

.Table.vertical-bar span,
.Table.vertical-bar-alert span,
.Table.vertical-bar-blocked span {
  margin-bottom: 41%;
}

.Table.horizontal-bar {
  /* background-image: url(/bar-horizontal@2x.png); */
  background-repeat: no-repeat;
}

.Table.horizontal-bar-alert {
  /* background-image: url(/bar-horizontal-alert@2x.png); */
  background-repeat: no-repeat;
}

.Table.horizontal-bar-active {
  /* background-image: url(/bar-horizontal-active@2x.png); */
  background-repeat: no-repeat;
}

.Table.horizontal-bar-blocked {
  /* background-image: url(/bar-horizontal-blocked@2x.png); */
  background-repeat: no-repeat;
}

.Table.horizontal-bar span,
.Table.horizontal-bar-alert span,
.Table.horizontal-bar-blocked span {
  margin-right: 50%;
}

.Table.Free:after {
  content: '';
  height: 120%;
  width: 120%;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  position: absolute;
  z-index: -1;
}

.Table.horizontal-2.Free:after {
  height: 200%;
  width: 150%;
}

.Table.vertical-2.Free:after {
  height: 150%;
  width: 200%;
}

.ChartContainer {
  -webkit-flex-grow: 10;
          flex-grow: 10;
  overflow: scroll;
  position: relative;
}

.ChartView .SideBarContainer {
  display: none;
}

.LegendLeft {
  width: 60px;
  color: var(--color-text);
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  left: 0px;
  z-index: 3;
  background: var(--color-bg);
}

.LegendRow {
  height: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 0px 10px;
}

.LegendTop {
  position: absolute;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  height: 30px;
  top: 0px;
  left: 180px;
  color: var(--color-text);
}

.LegendColumn {
  width: 240px;
  height: 20px;
  border-left: solid 1px var(--color-text);
  display: -webkit-flex;
  display: flex;
  padding-left: 5px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.Chart {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  overflow-y: scroll;
}

.ChartContainer table {
  border-spacing: 0px;
  position: absolute;
  top: 30px;
  left: 180px;
  border-collapse: collapse;
  opacity: 0.25;
}

.ChartContainer td {
  min-width: 60px;
  height: 30px;
  border: 1px solid var(--color-text);
}

td:nth-child(4n) {
  border-right-width: 2px;
}

.Booking {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 5px;
  cursor: pointer;
}

.AddButton {
  position: absolute !important;
  bottom: 20px;
  right: 20px;
  z-index: 10;
}

.MuiFab-primary {
  background-color: var(--color-primary) !important;
  color: var(--color-onPrimary) !important;
}

.MuiDialogTitle-root h2 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.MuiStepIcon-active,
.MuiStepIcon-completed {
  color: var(--color-secondary) !important;
}

.Loading {
  height: 100vh;
  width: 100vw;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.react-calendar {
  width: 100% !important;
  border: none !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  background: transparent;
  color: var(--color-text);
}

.react-calendar * {
  color: var(--color-text) !important;
}

.react-calendar__navigation button[disabled],
.react-calendar__tile:disabled {
  background-color: rgba(255, 255, 255, 0.11) !important;
}

button.react-calendar__tile--now {
  background: rgba(245, 0, 87, 0.2);
}

abbr[title] {
  text-decoration: none !important;
  text-transform: capitalize;
}

.react-calendar__tile--active,
.react-calendar__tile--hasActive {
  background: var(--color-secondary) !important;
  color: var(--color-onSecondary) !important;
}

.react-calendar__navigation__label {
  font-size: 16px;
  font-weight: 500;
}

.Relocation .SideBarContainer,
.Relocation .ViewContainer {
  -webkit-transform: translate(-300px, 0px);
          transform: translate(-300px, 0px);
}

.TimeTable {
  position: relative;
  height: 100%;
  overflow: scroll;
}

.TimeTable table {
  /* position: absolute; */
  top: 0px;
  left: 0px;
  border-collapse: collapse;
}

.TimeTable tr {
  height: 30px;
}

.TimeTable tr:nth-child(2n - 1) {
  border-top: solid 1px rgba(255, 255, 255, 0.3);
}

.lightMode .TimeTable tr:nth-child(2n - 1) {
  border-top: solid 1px rgba(0, 0, 0, 0.3);
}

.activeTable {
  background: rgba(255, 255, 255, 0.11);
}

.lightMode .activeTable {
  background: rgba(0, 0, 0, 0.11);
}

.TimeTable .time {
  display: block;
  width: 30px;
  color: var(--color-text);
  font-size: 10px;
  text-align: left;
}

.TimeTable .view {
  width: 250px;
}

.TimeTable .currentTime {
  position: absolute;
  width: 280px;
  left: 0px;
  height: 2px;
  background: var(--color-text);
  z-index: 1;
}

.TimeTable .currentTime::before {
  content: '';
  display: block;
  height: 6px;
  width: 6px;
  border-radius: 3px;
  background: var(--color-text);
  margin-top: -2px;
  margin-left: -4px;
}

.reservation {
  border-radius: 4px;
  border: solid 1px var(--color-text);
  padding: 10px;
  color: var(--color-text);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.reservation .bookingHeader {
  font-size: 14px;
}

.reservation .bookingTime {
  font-size: 14px;
  opacity: 0.5;
}

.tableSwitch {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.tableSwitch > .reservation {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.tableSwitch > svg {
  margin: 10px;
}

.TimeTable .reservation {
  position: absolute;
  left: 40px;
  width: 228px;
  z-index: 2;
  background: var(--color-depth6);
}

.TimeTable .reservation.sm,
.TimeTable .reservation.xs {
  padding: 4px;
}

.TimeTable .reservation.sm * {
  font-size: 90%;
  line-height: 1.4em !important;
}

.TimeTable .reservation.xs * {
  font-size: 90%;
  line-height: 1em !important;
}

.TimeTable .overlap {
  position: absolute;
  left: 40px;
  width: 240px;
  border-radius: 4px;
  border: solid 1px var(--color-text);
  background: var(--color-depth6);
  padding: 10px;
  color: var(--color-text);
  z-index: 3;
  background: red;
  background: repeating-linear-gradient(
    45deg,
    red,
    red 10px,
    #2f2f2f 10px,
    #2f2f2f 20px
  ) !important;
  pointer-events: none;
  opacity: 0.4;
}

@media (hover: hover) {
  .Booking-Hover {
    opacity: 0.9;
    -webkit-filter: brightness(1.5);
            filter: brightness(1.5);
  }

  header:hover {
    opacity: 1;
  }

  .SideBarElement:hover,
  .SideBarElement.Hover {
    filter: brightness(1.2);
    -webkit-filter: brightness(1.2);
  }

  .Table:hover::before {
    content: '';
    height: 120%;
    width: 120%;
    border: solid 2px var(--color-text);
    position: absolute;
    border-radius: 5px;
  }

  .EditBody .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: rgba(255, 255, 255, 0.2);
  }

  .react-calendar__tile:hover,
  .react-calendar__navigation button:enabled:hover {
    background-color: rgba(255, 255, 255, 0.11) !important;
  }
}

body.Dashboard {
  position: fixed;
}

.Settings-Table-Hover::before {
  content: '';
  height: 120%;
  width: 120%;
  border: solid 2px #acacac;
  position: absolute;
  border-radius: 5px;
  z-index: -1;
  -webkit-transform: translate(-10%, -10%);
          transform: translate(-10%, -10%);
}

.MuiTypography-colorTextSecondary {
  color: rgba(255, 255, 255, 0.7) !important;
}

.lightMode .MuiTypography-colorTextSecondary {
  color: rgba(0, 0, 0, 0.7) !important;
}

.MuiChip-root .MuiChip-avatarColorSecondary {
  color: #fff;
  background-color: transparent !important;
}

.RightSide ::-webkit-scrollbar {
  background-color: rgba(255, 255, 255, 0);
  width: 0px !important;
  height: 0px !important;
}

.RightSide {
  overflow-x: hidden;
}

.switchTable {
  display: -webkit-inline-flex;
  display: inline-flex;
  height: 30px;
  width: 30px;
  position: absolute;
  right: -10px;
  bottom: -10px;
  background: #017957;
  border-radius: 15px;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  z-index: 5;
  color: white;
}

.switchTable.notAvailable {
  background: #c43737;
}

#anlass {
  text-transform: capitalize;
}

.drag-n-drop {
  width: 100%;
}

.drag-n-drop strong {
  font-weight: 500;
}

.drag-n-drop .category:hover .categoryDrag {
  opacity: 1;
}

.drag-n-drop .categoryDrag {
  position: absolute;
  left: -24px;
  top: 22px;
  opacity: 0;
  color: #bdbdbd;
}

.drag-n-drop .categoryHeader {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.drag-n-drop .category-exp,
.drag-n-drop .category-exp-enter,
.drag-n-drop .category-exp-enter-active,
.drag-n-drop .category-exp-enter-done,
.drag-n-drop .category-exp-exit,
.drag-n-drop .category-exp-exit-active {
  transition: all 500ms ease;
}

.drag-n-drop .category-exp-enter {
  opacity: 0;
  -webkit-transform: translateY(-20px);
          transform: translateY(-20px);
}
.drag-n-drop .category-exp-enter-active {
  opacity: 1;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
}

.drag-n-drop .category-exp-enter-done {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
}

.drag-n-drop .category-exp-exit {
  opacity: 1;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
}
.drag-n-drop .category-exp-exit-active {
  opacity: 0;
  -webkit-transform: translateY(-20px);
          transform: translateY(-20px);
}

.drag-n-drop .item:hover .itemDrag {
  opacity: 1;
}

.drag-n-drop .item,
.drag-n-drop .item-inner {
  display: -webkit-flex;
  display: flex;
  position: relative;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.drag-n-drop .item-inner {
  -webkit-flex-shrink: 10;
          flex-shrink: 10;
  overflow: hidden;
  margin-left: -10px;
}

.drag-n-drop .item-inner * {
  white-space: nowrap;
}

.drag-n-drop .itemDrag {
  position: absolute;
  left: -28px;
  top: 3px;
  opacity: 0;
  color: #bdbdbd;
}

.drag-n-drop .icon-btn {
  opacity: 0.3;
  color: var(--color-text);
}

.drag-n-drop .icon-btn:hover,
.drag-n-drop .icon-btn.active {
  opacity: 0.8;
}

.del-btn {
  opacity: 0;
}

.field {
  margin-bottom: 10px;
}

.field:hover .del-btn {
  opacity: 1;
}

